import React, { useEffect } from "react";
import CodeTemplate from "../../../Templetes/Categories/Details/Robomission/Robomission.template";
import RobomissionTemplate from "../../../Templetes/Categories/Details/Robomission/Robomission.template";

const RobomissionPage = () => {
  useEffect(() => {
    document.title = "We Do Challenge - Robomission Challenge";
  }, []);
  return <RobomissionTemplate />;
};

export default RobomissionPage;
