import React, { useEffect } from "react";
import GalleryTemplate from "../../Templetes/Gallery/Gallery.template";

const GalleryPage = () => {
  useEffect(() => {
    document.title = "We Do Challenge - History";
  }, []);
  return <GalleryTemplate />;
};

export default GalleryPage;
