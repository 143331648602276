import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./Count.css";
const Count = ({ days = 0, hours = 0, minutes = 0, seconds = 0 }) => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "800px",
        margin: "20px auto 0 auto",
        border: "solid 5px #23aef4",
        borderRadius:"29px",
      }}
    >
      <Grid container columns={11} alignItems={"center"}>
        <Grid
          item
          xs={5}
          md={2}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              backgroundColor: "",
              flexDirection: "column",
            }}
            className="CounterNumber"
          >
            {days < 9 ? `0${days}` : days}
            <Typography
              sx={{
                color: "secondary.light",
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "8px",
              }}
            >
              DAYS
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "secondary.light",
              fontWeight: "900",
              fontSize: "60px",
            }}
          >
            :
          </Typography>
        </Grid>
        <Grid
          item
          xs={5}
          md={2}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              backgroundColor: "",
              flexDirection: "column",
            }}
            className="CounterNumber"
          >
            {hours < 9 ? `0${hours}` : hours}
            <Typography
              sx={{
                color: "secondary.light",
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "8px",
              }}
            >
              HOURS
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "secondary.light",
              fontWeight: "900",
              fontSize: "60px",
            }}
          >
            :
          </Typography>
        </Grid>
        <Grid
          item
          xs={5}
          md={2}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: { xs: "40px", md: "0" },
          }}
        >
          <Box
            sx={{ backgroundColor: "", flexDirection: "column" }}
            className="CounterNumber"
          >
            {minutes < 9 ? `0${minutes}` : minutes}
            <Typography
              sx={{
                color: "secondary.light",
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "8px",
              }}
            >
              MINUTES
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "secondary.light",
              fontWeight: "900",
              fontSize: "60px",
            }}
          >
            :
          </Typography>
        </Grid>
        <Grid
          item
          xs={5}
          md={2}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: { xs: "40px", md: "0" },
          }}
        >
          <Box
            sx={{ backgroundColor: "", flexDirection: "column" }}
            className="CounterNumber"
          >
            {seconds < 9 ? `0${seconds}` : seconds}
            <Typography
              sx={{
                color: "secondary.light",
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "8px",
              }}
            >
              SECONDS
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Count;
