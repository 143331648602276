import React, { useEffect } from "react";
import PrivacyTemplate from "../../Templetes/Privacy/Privacy.template";

const PrivacyPage = () => {
  useEffect(() => {
    document.title = "We Do Challenge - Privacy Policy";
  }, []);
  return <PrivacyTemplate />;
};

export default PrivacyPage;
