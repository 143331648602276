import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Images } from "../../helpers/Images";
import { useLocation } from "react-router-dom";
import axios from "axios";

const TermsTemplate = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "35px", sm: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          We Do Challenge's Terms & Condition
        </Typography>
        <Typography
          sx={{
            textAlign: "start",
            fontSize: "20px",
            fontWeight: "400",
            color: "primary.darker",
          }}
        >
          Last Updated: May, 2024
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "150px" },
            width: "100%",
            backgroundColor: "primary.main",
            marginTop: "5px",
            marginBottom: "50px"
          }}
        />
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          These Terms and Conditions ("Terms") govern your use of the We Do Challenge website ("Website"). By accessing or using the Website, you agree to be bound by these Terms.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          General Information:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          These Terms govern the application process for the We Do Challenge, including registration, fees, and taxes: <br /><br />
          <ul>
            <li><span style={{ fontWeight: "400" }}>Age Restrictions:</span> There are no age restrictions for users of the Website. However, participants in the We Do Challenge may be subject to age restrictions outlined in a separate competition agreement.</li>
            <li><span style={{ fontWeight: "400" }}>Account Information:</span> Currently, users are not required to create an account to use the Website or submit an application.</li>
          </ul>
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Competition Agreement:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          Please be aware that a separate competition agreement may exist outlining additional rules and regulations specific to the We Do Challenge itself.  This agreement may include eligibility requirements, intellectual property ownership, and other relevant details.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Purchases and Payments:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          These Terms govern the application process for the We Do Challenge, including registration, fees, and taxes: <br /><br />
          <ul>
            <li><span style={{ fontWeight: "400" }}>Payment Methods:</span> We currently accept online card payments for application fees.</li>
            <li><span style={{ fontWeight: "400" }}>Application Fees:</span> The application fee includes all applicable taxes calculated as follows: 2.75% of the application fee, plus 14% of this percentage, plus an additional 3 EGP. This ensures a transparent fee structure.</li>
          </ul>
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          User Conduct:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          You agree to use the Website in a lawful manner and in accordance with these Terms. You will not:<br /><br />
          <ul>
            <li>Engage in any illegal activity.</li>
            <li>Harass or abuse other users.</li>
            <li>Disrupt the operation of the Website.</li>
            <li>Violate any intellectual property rights.</li>
            <li>Attempt to gain unauthorized access to the Website or any systems or networks connected to the Website.</li>
            <li>Use the Website for any purpose that is not expressly permitted by these Terms.</li>
          </ul>
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Intellectual Property:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          The Website and all content on the Website, including but not limited to text, graphics, logos, images, and software, are the property of We Do Challenge or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not copy, reproduce, modify, distribute, transmit, display, publish, sell, license, or create derivative works from any content on the Website without the express written permission of We Do Challenge.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Disclaimer of Warranties:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          The Website is provided "as is" and without warranties of any kind, whether express or implied. We Do Challenge disclaims all warranties, including, but not limited to, the warranties of merchantability, fitness for a particular purpose, and non-infringement. We Do Challenge does not warrant that the Website will be uninterrupted or error-free, that defects will be corrected, or that the Website or the server that makes it available are free of viruses or other harmful components.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Limitation of Liability:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          We Do Challenge shall not be liable for any damages arising out of or in connection with your use of the Website. This includes, but is not limited to, direct, indirect, incidental, consequential, special, punitive, or exemplary damages.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Termination:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          We Do Challenge may terminate your access to the Website at any time, for any reason, without notice.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Changes to Terms:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          We may update these Terms at any time by posting the revised Terms on the Website. Your continued use of the Website after the posting of the revised Terms constitutes your acceptance of the changes.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Entire Agreement:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          These Terms constitute the entire agreement between you and We Do Challenge with respect to your use of the Website.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Contact Us:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          If you have any questions regarding this Privacy Policy, please do not hesitate to contact us at: wedochallenge1@gmail.com
        </Typography>
      </Box>
    </>
  );
};

export default TermsTemplate;
