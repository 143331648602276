import React from "react";
import { Box, Typography } from "@mui/material";
import { Images } from "../../../../helpers/Images";

const Theme = () => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <Typography
        variant={"h1"}
        sx={{
          textAlign: "center",
          fontSize: { xs: "40px", sm: "55px" },
          fontWeight: "500",
          color: "primary.darker",
        }}
      >
        We Do Challenge 2024 Theme
      </Typography>
      <Box
        sx={{
          height: "3px",
          maxWidth: { xs: "100px", md: "150px" },
          width: "100%",
          backgroundColor: "#23aef4",
          margin: "auto",
          marginTop: "10px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          alignItems: "center",
          marginTop: "15px",
          maxWidth: "1000px",
          margin: "auto",
        }}
      >
        <Typography
          sx={{
            textAlign: "start",
            color: "primary.darker",
            width: "100%",
            maxWidth: "800px",
            margin: "auto",
            marginTop: { xs: "0", md: "10px" },
            marginBottom: "20px",
            display: "block",
          }}
        >
          <span style={{ color: "primary.darker", fontSize: " 25px" }}>
            {" "}
            Doctor Robot
          </span>
          <br />
          Calling all future medical marvels! Get ready to unleash your inner
          power!
          <span style={{ color: "#23aef4" }}> Doctor Robot</span> is a fantastic
          challenge to spark creativity and engage kids in learning about health
          and technology. It offers a unique way for kids to learn about healthcare advancements
          while promoting positive messages about the future of medicine.
        </Typography>
        <Box sx={{ marginTop: { xs: "10px", md: "0" } }}>
          <img style={{ height: "200px" }} src={Images.wedoLogo} />
        </Box>
      </Box>
    </Box>
  );
};

export default Theme;
