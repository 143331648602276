import { Box, Button, Typography, Grid } from "@mui/material";
import React from "react";
import "./CountDown.css";
import Countdown from "react-countdown";
import Count from "../../../molecules/Count/Count";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";

const CountDown = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        padding: {
          xs: "50px 20px",
          sm: "50px 60px",
          lg: "50px 120px",
          xl: "150px 120px",
        },
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${Images.gold})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "500px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography
        variant={"h1"}
        sx={{
          textAlign: "center",
          fontSize: { xs: "50px", sm: "55px" },
          fontWeight: "500",
          color: "secondary.main",
        }}
      >
        Welcome to We Do Challenge Championship 2024
      </Typography>
      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontSize: { xs: "20px", sm: "30px" },
          fontWeight: "500",
          marginTop: "5px",
          color: "secondary.main",
        }}
      >
        ~ 19 - 09 - 2024 ~
      </Typography>
      <div className="count">
        <Countdown
          date={"2024-09-19T24:00:00"}
          intervalDelay={0}
          renderer={(props) => (
            <Count
              days={props.days}
              hours={props.hours}
              minutes={props.minutes}
              seconds={props.seconds}
            />
          )}
        />
      </div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          marginTop: "20px",
        }}
      ></Box>
    </Box>
  );
};

export default CountDown;
