import React, { useEffect } from "react";
import HomeTemplate from "../../Templetes/Home/Home.template";

const HomePage = () => {
  useEffect(() => {
    document.title = "We Do Challenge Championship";
  }, []);
  return <HomeTemplate />;
};

export default HomePage;
