import React, { useEffect } from "react";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import "./TeamAccordion.css";
import TextFieldInput from "../../../atoms/Inputs/TextField/TextFieldInput";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { PhoneInput } from "react-international-phone";
import { useState } from "react";
import phone from "phone";
import dayjs from "dayjs";
import * as Yup from "yup";
import { Formik, useFormikContext } from "formik";
import DeleteForm from "../../DeleteForm/DeleteForm";
import MemberCard from "../MemberCard/MemberCard";

const sortTeams = (a, b) => {
  if (a?.id > b?.id) {
    return 1;
  } else {
    return -1;
  }
};

const validationSchema = Yup.object().shape({
  id: Yup.string().required(),
  name: Yup.string().required("Team name is required!"),
  category_id: Yup.string().required("Category is required!"),
  age_group_id: Yup.string().required("Age group is required!"),
  coach_name: Yup.string().required("Coach name is required!"),
  coach_email: Yup.string()
    .email("Please enter a valid email!")
    .required("Coach email is required!"),
  students: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Student name is required!"),
      nationalId: Yup.string().matches(/^[0-9]+$/, "Valid National ID is required!").min(8, "Valid National ID is required!").max(20, "Valid National ID is required!").required("Valid National ID is required!"),
      birthday: Yup.date().required("Birthday is required!"),
    })
  ),
});

const CheckValidation = ({ handleNewData, phoneValidation, teamsNumber }) => {
  const { isValid, values } = useFormikContext();
  useEffect(() => {
    handleNewData({
      target: {
        name: "isValid",
        value: isValid && phoneValidation && teamsNumber,
      },
    });
  }, [values, isValid, phoneValidation, teamsNumber]);
  return null;
};

const TeamAccordion = ({
  teamName,
  index,
  categories,
  teams,
  team,
  first,
  last,
  setNewData,
  submitClicked,
  accordionClicked,
  expandedAccordions,
  setDeletedTeams,
  deletedTeams,
  collapseAll,
  setLoading,
}) => {
  const [Phone, setPhone] = useState(team?.coach_contactNumber ?? "");
  const [PhoneError, setPhoneError] = useState(null);
  const [PhoneTouched, setPhoneTouched] = useState(false);

  useEffect(() => {
    if (
      !phone(Phone)?.isValid ||
      (Phone?.length < 13 && phone(Phone)?.countryCode == "+20")
    )
      setPhoneError("Valid Phone Number Required!!");
    else setPhoneError("");
  }, [Phone]);

  const handleNewData = (e, handle) => {
    if (e.target.name == "category_id") {
      setNewData(
        [
          ...teams?.filter((item) => item?.id != team?.id),
          {
            ...teams?.find((item) => item?.id == team?.id),
            [e.target.name]: e.target.value,
            age_group_id: ""
          },
        ].sort(sortTeams)
      );
      if (handle) { handle(e); handle({ target: { name: "age_group_id", value: "" } }) };
    } else {
      setNewData(
        [
          ...teams?.filter((item) => item?.id != team?.id),
          {
            ...teams?.find((item) => item?.id == team?.id),
            [e.target.name]: e.target.value,
          },
        ].sort(sortTeams)
      );
      if (handle) handle(e);
    }
  };

  return (
    <Accordion
      key={index}
      onChange={() => accordionClicked(index)}
      expanded={expandedAccordions.includes(index)}
    >
      <AccordionSummary
        className={`accordionHeader ${first ? "firstAcc" : last ? "lastAcc" : ""
          }`}
        expandIcon={<ArrowDropDownIcon style={{ color: "white" }} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>
          {index + 1} {team?.name ? `- ${team?.name}` : `- ${teamName}`}
          {submitClicked && !teams?.find((te) => te?.id == team?.id)?.isValid
            ? " - This team is not completed yet!"
            : ""}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            id: team?.id ?? "",
            name: team?.name ?? "",
            category_id: team?.category_id ?? "",
            age_group_id: team?.age_group_id ?? "",
            coach_name: team?.coach_name ?? "",
            coach_email: team?.coach_email ?? "",
            coach_contactNumber: team?.coach_contactNumber ?? "",
            students: team?.students ?? [],
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setTouched,
          }) => (
            <form
              className="FormContainer"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div className="FlexHeader" style={{ marginTop: "0" }}>
                <h3 style={{ fontWeight: "500" }}>Section 1 - Team's Data</h3>
                <DeleteForm
                  disabled={teams?.length == 1}
                  click={() => {
                    setLoading(true);
                    setTimeout(() => {
                      collapseAll();
                      if (!team?.New)
                        setDeletedTeams([...deletedTeams, team?.id]);
                      setNewData(teams?.filter((item) => item?.id != team?.id));
                      setLoading(false);
                    }, 500);
                  }}
                  title={"Are you sure to delete this team ?"}
                />
              </div>
              <div className="FormContainerBox">
                <div>
                  <TextFieldInput
                    name="name"
                    type="text"
                    placeholder={"Enter team's name"}
                    className="Input"
                    label="Team Name"
                    blur={handleBlur}
                    change={(e) => handleNewData(e, handleChange)}
                    newValue={values?.name}
                  />
                  {errors.name && (touched.name || submitClicked) && (
                    <p className="error">{errors.name}</p>
                  )}
                </div>
                <div>
                  <TextFieldInput
                    select
                    newValue={values?.category_id}
                    placeholder={"Choose Team Category"}
                    name={"category_id"}
                    change={(e) => {
                      handleNewData(
                        {
                          target: {
                            name: "students",
                            value: [],
                          },
                        },
                        handleChange
                      );
                      handleNewData(e, handleChange);
                    }}
                    blur={handleBlur}
                    fullWidth
                    className={`Input`}
                    variant="outlined"
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    <MenuItem value="" className="" disabled>
                      Choose Team Category
                    </MenuItem>
                    {categories?.map((item, i) => (
                      <MenuItem value={item?.id} key={i}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </TextFieldInput>
                  {errors.category_id &&
                    (touched.category_id || submitClicked) && (
                      <p className="error">{errors.category_id}</p>
                    )}
                </div>
                <div>
                  <TextFieldInput
                    select
                    newValue={values?.age_group_id}
                    placeholder={"Choose Age Group"}
                    name={"age_group_id"}
                    change={(e) => {
                      handleNewData(
                        {
                          target: {
                            name: "students",
                            value: [],
                          },
                        },
                        handleChange
                      );
                      handleNewData(e, handleChange);
                    }}
                    blur={handleBlur}
                    fullWidth
                    className={`Input`}
                    variant="outlined"
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    <MenuItem value="" className="" disabled>
                      Choose Age Group
                    </MenuItem>
                    {categories
                      ?.find((cat) => cat?.id == values?.category_id)
                      ?.ageGroups?.map((item, i) => (
                        <MenuItem value={item?.id} key={i}>
                          {item?.name} ( {item?.minAge} - {item?.maxAge} years )
                        </MenuItem>
                      ))}
                  </TextFieldInput>
                  {errors.age_group_id &&
                    (touched.age_group_id || submitClicked) && (
                      <p className="error">{errors.age_group_id}</p>
                    )}
                </div>
              </div>
              <h3 style={{ fontWeight: "500" }}>Section 2 - Coach's Data</h3>
              <div className="FormContainerBox">
                <div>
                  <TextFieldInput
                    name="coach_name"
                    type="text"
                    placeholder={"Enter team's coach name"}
                    className="Input"
                    label="Coach Name"
                    change={(e) => handleNewData(e, handleChange)}
                    blur={handleBlur}
                    newValue={values?.coach_name}
                  />
                  {errors.coach_name &&
                    (touched.coach_name || submitClicked) && (
                      <p className="error">{errors.coach_name}</p>
                    )}
                </div>
                <div>
                  <TextFieldInput
                    name="coach_email"
                    type="email"
                    placeholder={"Enter team's coach email"}
                    className="Input"
                    label="Coach Email"
                    change={(e) => handleNewData(e, handleChange)}
                    blur={handleBlur}
                    newValue={values?.coach_email}
                  />
                  {errors.coach_email &&
                    (touched.coach_email || submitClicked) && (
                      <p className="error">{errors.coach_email}</p>
                    )}
                </div>
                <div style={{ position: "relative" }}>
                  <label className="labelPhone">Coach Phone Number*</label>
                  <PhoneInput
                    label={"Enter Coach Phone Number"}
                    className="textfield Input phoneInput"
                    value={Phone}
                    defaultCountry="eg"
                    onBlur={() => setPhoneTouched(true)}
                    onChange={(e) => {
                      setPhone(e);
                      handleNewData(
                        {
                          target: {
                            name: "coach_contactNumber",
                            value: e,
                          },
                        },
                        handleChange
                      );
                    }}
                  />
                  {PhoneError && (PhoneTouched || submitClicked) ? (
                    <p className="error">{PhoneError}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="FlexHeader">
                <div>
                  <h3 style={{ fontWeight: "500" }}>
                    Section 4 - Team Members' Data
                  </h3>
                  <p>Click Add Team Member to add a new member to the team..</p>
                  <p>
                    Note: Minimum number of students is{" "}
                    <strong>
                      {
                        categories?.find(
                          (cat) => cat?.id == values?.category_id
                        )?.minStudents
                      }
                    </strong>
                    , and maximum number is{" "}
                    <strong>
                      {
                        categories?.find(
                          (cat) => cat?.id == values?.category_id
                        )?.maxStudents
                      }
                    </strong>
                    {categories?.find((cat) => cat?.id == values?.category_id)
                      ?.minStudents > values?.students?.length &&
                      submitClicked && (
                        <p className="error">
                          Please Add at least{" "}
                          {
                            categories?.find(
                              (cat) => cat?.id == values?.category_id
                            )?.minStudents
                          }{" "}
                          student
                        </p>
                      )}
                  </p>
                </div>
                <Box
                  sx={{
                    width: { xs: "100%", md: "fit-content" },
                    display: "flex",
                    flexDirection: { xs: "row", md: "column" },
                    justifyContent: { xs: "space-between", md: "flex-end" },
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      categories?.find((cat) => cat?.id == values?.category_id)
                        ?.maxStudents <= values?.students?.length
                    }
                    onClick={() => {
                      setTouched({
                        ...touched,
                        category_id: true,
                        age_group_id: true,
                      });
                      if (values?.age_group_id)
                        handleNewData(
                          {
                            target: {
                              name: "students",
                              value: [
                                ...values?.students,
                                {
                                  id: values?.students?.length
                                    ? values.students?.at(-1)?.id + 1
                                    : 1,
                                  name: "",
                                  nationalId: "",
                                  birthday: dayjs(
                                    categories
                                      ?.find(
                                        (cat) => cat?.id == values?.category_id
                                      )
                                      ?.ageGroups?.find(
                                        (age) => age?.id == values?.age_group_id
                                      )
                                      ?.minBirthday?.split("T")
                                      ?.at(0)
                                  ),
                                },
                              ],
                            },
                          },
                          handleChange
                        );
                    }}
                  >
                    Add Team Member
                  </Button>
                  <h2 style={{ textAlign: "right", marginTop: "5px" }}>
                    {values?.students?.length}/
                    {
                      categories?.find((cat) => cat?.id == values?.category_id)
                        ?.maxStudents
                    }
                  </h2>
                </Box>
              </div>
              <div className="teamsCont">
                {values?.students?.length
                  ? values?.students?.map((member, i) => (
                    <MemberCard
                      key={i}
                      count={i + 1}
                      categories={categories}
                      members={values?.students}
                      member={member}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      handleNewData={handleNewData}
                      errors={errors?.students?.at(i)}
                      touched={touched?.students?.at(i)}
                      submitClicked={submitClicked}
                    />
                  ))
                  : ""}
              </div>
              <CheckValidation
                handleNewData={handleNewData}
                phoneValidation={PhoneError ? false : true}
                teamsNumber={
                  categories?.find((cat) => cat?.id == values?.category_id)
                    ?.minStudents <= values?.students?.length &&
                  categories?.find((cat) => cat?.id == values?.category_id)
                    ?.maxStudents >= values?.students?.length
                }
              />
            </form>
          )}
        </Formik>
      </AccordionDetails>
    </Accordion>
  );
};

export default TeamAccordion;

{
  /* <h3 style={{ fontWeight: "500" }}>Section 3 - Team Options</h3>
        <div className="FormContainerBox">
          <div>
            <Checkbox
              id="additional"
              checked={team?.options?.additional_category}
              onChange={() => setAdditionalCategory(!additionalCategory)}
            />
            <label for="additional">
              Participate in other category with this team ?
            </label>
            {additionalCategory ? (
              <>
                <TextFieldInput
                  select
                  newValue={team.team_category}
                  placeholder={"Choose Team 2nd Category"}
                  name={"team_category"}
                  change={handleChange}
                  fullWidth
                  className={`Input`}
                  variant="outlined"
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="" className="" disabled>
                    Choose Team 2nd Category
                  </MenuItem>
                  {categories?.map((item) => (
                    <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  ))}
                </TextFieldInput>
              </>
            ) : (
              ""
            )}
          </div>
          <div>
            <Checkbox label="additional category" />
            <label>Do you have an additional member to the team ?</label>
          </div>
        </div> */
}
