import React, { useEffect } from "react";
import ScientificTemplate from "../../../Templetes/Categories/Details/Scientific/Scientific.template";

const ScientificPage = () => {
  useEffect(() => {
    document.title = "We Do Challenge - Scientific Challenge";
  }, []);
  return <ScientificTemplate />;
};

export default ScientificPage;
