import axios from "axios";
import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const initialState = {
  dataRes: [],
  loadingRes: true,
  errorRes: null,
};

const ACTION = {
  API_REQUEST: "api-request",
  FETCH_DATA: "fetch-data",
  ERROR: "error",
};

function reducer(saveState, { type, payload }) {
  switch (type) {
    case ACTION.API_REQUEST:
      return { ...saveState, loadingRes: true, errorRes: null };
    case ACTION.FETCH_DATA:
      return { ...saveState, dataRes: payload, loadingRes: false };
    case ACTION.ERROR:
      return { ...saveState, loadingRes: false, errorRes: payload };
    default:
      return saveState;
  }
}
function useSave({
  url,
  goSave,
  setGoSave,
  newObject,
  navigateAfter = false,
  popup = true,
}) {
  const navigate = useNavigate();
  const [saveState, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (goSave) {
      setLoading(true);
      dispatch({ type: ACTION.API_REQUEST });
      axios
        .post(url, newObject, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/app.js",
            // Authorization: `Bearer ${cookies.token}`,
          },
        })
        .then((res) => {
          dispatch({ type: ACTION.FETCH_DATA, payload: res });
          setLoading(false);
          if (popup)
            toast.success(res?.data?.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          if (navigateAfter) {
            navigate(navigateAfter);
          }
          // if (setNewData) Fetch(FetchUrl ?? url, cookies, setNewData);
        })
        .catch((err) => {
          dispatch({ type: ACTION.ERROR, payload: err.error });
          setLoading(false);
          if (err?.response?.data?.error?.errors) {
            err?.response?.data?.error?.errors.forEach((e) => {
              toast.error(e.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            });
          } else if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
      setGoSave(false);
    }
  }, [goSave]);
  return { saveState, loading };
}

export default useSave;
