import React, { useEffect } from "react";
import CodeTemplate from "../../../Templetes/Categories/Details/Robomission/Robomission.template";
import FollowlineTemplate from "../../../Templetes/Categories/Details/Followline/Followline.template";

const FollowlinePage = () => {
  useEffect(() => {
    document.title = "We Do Challenge - Followline Challenge";
  }, []);
  return <FollowlineTemplate />;
};

export default FollowlinePage;
