import { Box, Button, Grid, Typography } from "@mui/material";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { categoriesData } from "../../../../helpers/Arrays";
import { isMobile } from "react-device-detect";

const CategoriesCarousel = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "primary.main",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "1500px",
          padding: {
            xs: "50px 20px 100px 20px",
            sm: "50px 60px",
            lg: "70px 120px",
          },
          margin: "auto",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontSize: "35px",
            fontWeight: "500",
            color: "secondary.main",
            marginBottom: "15px",
          }}
        >
          WeDo Challenge Categories
        </Typography>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <a
            href={"/categories"}
            style={{ textDecoration: "none", color: "#010102" }}
          >
            <Button
              variant="contained"
              color="secondary"
              sx={{
                fontSize: "15px",
                fontWeight: "600",
                color: "primary.main",
                // backgroundColor: "secondary.main",
              }}
            >
              view more
            </Button>
          </a>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "100%" },
            margin: "auto",
          }}
        >
          <Carousel
            showStatus={false}
            showThumbs={false}
            showArrows={true}
            showIndicators={false}
            infiniteLoop={true}
            autoPlay={true}
            centerMode={isMobile ? false : true}
            centerSlidePercentage={50}
            renderArrowPrev={(onClickHandler) => (
              <Box
                onClick={onClickHandler}
                sx={{
                  width: "60px",
                  height: "60px",
                  filter:
                    "invert(100%) sepia(8%) saturate(243%) hue-rotate(147deg) brightness(113%) contrast(100%)",
                  cursor: "pointer",
                  position: "absolute",
                  zIndex: 1,
                  left: { xs: "0", md: "-75px" },
                  top: { xs: "unset", md: "calc(50% - 30px)" },
                  bottom: { xs: "-75px", md: "unset" },
                }}
              >
                <img alt="prev" src={Images.prevArrow} />
              </Box>
            )}
            renderArrowNext={(onClickHandler) => (
              <Box
                onClick={onClickHandler}
                sx={{
                  width: "60px",
                  height: "60px",
                  filter:
                    "invert(100%) sepia(8%) saturate(243%) hue-rotate(147deg) brightness(113%) contrast(100%)",
                  cursor: "pointer",
                  position: "absolute",
                  zIndex: 1,
                  right: { xs: "0", md: "-75px" },
                  top: { xs: "unset", md: "calc(50% - 30px)" },
                  bottom: { xs: "-75px", md: "unset" },
                }}
              >
                <img alt="next" src={Images.nextArrow} />
              </Box>
            )}
          >
            {categoriesData?.map((item, i) => (
              <Grid
                container
                sx={{ width: "100%", margin: "0 !important" }}
                spacing={5}
                key={i}
              >
                <Grid
                  item
                  xs={12}
                  sx={{ padding: "20px !important" }}
                  // md={6}
                >
                  <Box
                    sx={{
                      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      width: "100%",
                      minHeight: "300px",
                      borderRadius: "10px",
                      
                    }}
                  >
                    <Typography
                      variant={"h3"}
                      sx={{
                        textAlign: "center",
                        fontSize: "35px",
                        fontWeight: "500",
                        color: "secondary.main",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Box
                      sx={{
                        height: "3px",
                        maxWidth: "90px",
                        width: "100%",
                        backgroundColor: "secondary.main",
                        margin: "5px auto 5px auto",
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "primary.main",
                          width: "fit-content",
                          margin: "10px 0 40px 0",
                        }}
                        onClick={() => navigate(item.link)}
                      >
                        view
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Carousel>
        </Box>
      </Box>
    </Box>
  );
};

export default CategoriesCarousel;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
