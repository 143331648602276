import React, { useEffect } from "react";
import TermsTemplate from "../../Templetes/Terms/Terms.template";

const TermsPage = () => {
  useEffect(() => {
    document.title = "We Do Challenge - Terms and Condition";
  }, []);
  return <TermsTemplate />;
};

export default TermsPage;
