import React from "react";
import CategoriesCarousel from "../../Components/organisms/Home/CategoriesCarousel/CategoriesCarousel";
import CountDown from "../../Components/organisms/Home/CountDown/CountDown";
import Highlights from "../../Components/organisms/Home/Highlights/Highlights";
import Numbers from "../../Components/organisms/Home/Numbers/Numbers";
import Ranking from "../../Components/organisms/Home/Ranking/Ranking";
import Reviews from "../../Components/organisms/Home/Reviews/Reviews";
import Theme  from  "../../Components/organisms/Home/Theme/Theme";
import Cover from "../../Components/organisms/Home/Cover/Cover";
import LogoCarouse from "../../Components/organisms/Home/LogoCarousel/LogoCarousel";

const HomeTemplate = () => {
  return (
    <>
      <CountDown />
      <Theme />
      <Cover/>
      <Numbers />
      <CategoriesCarousel />
      {/* <Ranking /> */}
      <LogoCarouse />
      <Highlights />
      <Reviews />
    </>
  );
};

export default HomeTemplate;
