import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Images } from "../../helpers/Images";
import { useLocation } from "react-router-dom";
import axios from "axios";

const PrivacyTemplate = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "35px", sm: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          We Do Challenge's Privacy Policy
        </Typography>
        <Typography
          sx={{
            textAlign: "start",
            fontSize: "20px",
            fontWeight: "400",
            color: "primary.darker",
          }}
        >
          Last Updated: May, 2024
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "150px" },
            width: "100%",
            backgroundColor: "primary.main",
            marginTop: "5px",
            marginBottom: "50px"
          }}
        />
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          We Do Challenge ("we," "us," or "our") appreciates your trust and is committed to protecting your privacy. This Privacy Policy describes how we collect, use, and disclose your personal information when you use our website (the "Service").
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Information We Collect:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          We collect information from you in two ways: <br /><br />
          <ul>
            <li><span style={{ fontWeight: "400" }}>Information you provide directly:</span> This encompasses any information you submit during the We Do Challenge registration process, including your name, email address, and contact number.</li>
            <li><span style={{ fontWeight: "400" }}>Information collected automatically:</span> This includes information passively collected through your use of the Service, such as your IP address, browsing activity, and device information.</li>
          </ul>
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Use of Information:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          We use the information we collect to: <br /><br />
          <ul>
            <li>Register your application for the We Do Challenge.</li>
            <li>Provide and improve the Service, such as by personalizing features and content.</li>
            <li>Analyze how the Service is used.</li>
            <li>Communicate with you about the Service, such as to send you important updates and announcements.</li>
          </ul>
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Information Sharing:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          We may share your information with third-party service providers who help us operate the Service, such as payment processors and data analytics providers. We will only share your information with these providers to the extent necessary for them to perform their services.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Your Rights:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          You have the right to access and update your personal information. You can:<br /><br />
          <ul>
            <li>View your application details.</li>
            <li>Update your application information.</li>
          </ul>
          To exercise these rights, please contact us at wedochallenge1@gmail.com.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Data Security:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          We take significant steps to safeguard your information from unauthorized access, disclosure, alteration, or destruction. We utilize secure servers to store your information. However, it's important to remember that no internet transmission or electronic storage method is completely foolproof, and we cannot guarantee absolute security.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Cookies:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          We currently do not employ cookies on our website. However, we reserve the right to utilize cookies in the future. Should this occur, we will update this Privacy Policy to reflect the change.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Additional Information:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          This policy is available in English. <br />
          These policies are subject to change. We will update this page if our policies change.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Contact Us:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          If you have any questions regarding this Privacy Policy, please do not hesitate to contact us at: wedochallenge1@gmail.com
        </Typography>
      </Box>
    </>
  );
};

export default PrivacyTemplate;
