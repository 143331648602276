import React from "react";
import { Images } from "../../../../helpers/Images";
import { Box } from "@mui/material";

const Cover = () => {
  return (
    <>
      {/* <Box
        sx={{
          width: "100%",
          padding: {
            xs: "50px 20px",
            sm: "50px 60px",
            lg: "50px 120px",
            xl: "150px 120px",
          },
          background: `url(${Images.wedocover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "500px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      ></Box> */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          background: "#6a0505",
        }}
      >
        <img
          src={Images.wedocover}
          style={{ width: "100%", maxHeight: "600px", objectFit: "cover" }}
        />
      </div>
    </>
  );
};

export default Cover;
