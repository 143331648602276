import { Box, Button, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./CheckApplication.css";
import TextFieldInput from "../../../Components/atoms/Inputs/TextField/TextFieldInput";
import { PulseLoader } from "react-spinners";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { BaseURL } from "../../../Hooks/URL";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TotalStyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    fontSize: 18,
    fontWeight: "900",
  },
}));

const CheckApplicationTemplate = ({
  check,
  Code,
  setCode,
  CheckApp,
  CodeError,
  setCodeError,
  application,
  loading,
  navigate,
  setPaySave,
  payLoading,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      {!check ? (
        <>
          <Typography
            variant={"h1"}
            sx={{
              textAlign: "center",
              fontSize: { xs: "40px", sm: "55px" },
              fontWeight: "500",
              color: "primary.darker",
            }}
          >
            Check Your Application
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "400",
              color: "primary.darker",
              marginTop: "20px",
            }}
          >
            To check the progress of your registration, please enter your
            application code in the field provided below..
          </Typography>
          <Box
            sx={{
              height: "3px",
              maxWidth: { xs: "100px", md: "250px" },
              width: "100%",
              backgroundColor: "primary.main",
              margin: "10px auto 50px auto",
            }}
          />
          <div style={{ maxWidth: "600px", margin: "auto" }}>
            <TextFieldInput
              name="admin_name"
              type="text"
              placeholder={"Enter your reference code"}
              className="Input"
              label="Check Application"
              change={(e) => {
                setCode(e.target.value);
                if (e.target.value) {
                  setCodeError("");
                }
              }}
              newValue={Code}
            />
            {CodeError && <p className="error">{CodeError}</p>}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
              marginBottom: "25px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ minWidth: "200px" }}
              onClick={CheckApp}
            >
              {loading ? (
                <div>
                  <PulseLoader size={6} color="white" />{" "}
                </div>
              ) : (
                "Check now"
              )}
            </Button>
          </div>
        </>
      ) : (
        ""
      )}
      {loading ? (
        check ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "100px 0",
            }}
          >
            <PulseLoader size={12} color="#6a0505" />
          </div>
        ) : (
          ""
        )
      ) : application ? (
        <>
          {check ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "0px",
                }}
              >
                <CheckCircleOutlineIcon className="successIcon" />
              </div>
              <Typography
                variant={"h1"}
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "40px", sm: "55px" },
                  fontWeight: "500",
                  color: "primary.darker",
                }}
              >
                {application?.secondApplied
                  ? application?.paied
                    ? "Thank you for your payment!"
                    : "Thank you for completing the second phase"
                  : "Thank you for your registration!"}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "primary.darker",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                {application?.secondApplied
                  ? application?.paied
                    ? "Congratulations! Your payment has been successfully processed, marking the completion of your registration process. We're thrilled to have you on board..."
                    : "We have successfully received your teams details.."
                  : "We have successfully received your application.."}
              </Typography>
            </>
          ) : (
            ""
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "0px",
              marginBottom: "40px",
            }}
          >
            <a
              href={`${BaseURL}/competetions/3/applications/${Code}/pdf?timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone
                }`}
              target="_blank"
            >
              <Button
                variant="contained"
                color="primary"
                style={{ minWidth: "150px", margin: "0 5px" }}
              >
                {false ? (
                  <div className="loadingSmallBox">
                    <PulseLoader size={6} color="#fff" />
                  </div>
                ) : (
                  "Download Summary PDF"
                )}
              </Button>
            </a>
            {!application?.secondApplied ? (
              <Button
                variant="contained"
                color="primary"
                style={{ minWidth: "150px", margin: "0 5px" }}
                onClick={() => navigate(`/laststage/${application?.code}`)}
              >
                Register your teams
              </Button>
            ) : (
              ""
            )}
            {application?.secondApplied && !application?.paied ? (
              <Button
                variant="contained"
                color="primary"
                style={{ minWidth: "150px", margin: "0 5px" }}
                onClick={() => setPaySave(true)}
              >
                {payLoading ? (
                  <div className="loadingSmallBox">
                    <PulseLoader size={6} color="#fff" />
                  </div>
                ) : (
                  "Proceed to Payment"
                )}
              </Button>
            ) : (
              ""
            )}
          </div>
          <div className="appSummary">
            <h2>Application Unique Code: {application?.code}</h2>
            {/* {application?.paymentTransaction ? (
              <h2 style={{ marginTop: "-10px" }}>
                Transaction ID: {application?.paymentTransaction?.id}
              </h2>
            ) : (
              ""
            )} */}
            <p>
              {application?.secondApplied
                ? application?.paied
                  ? "Congratulations on completing your registration! You can now access your application anytime using your unique application code. Additionally, feel free to download a summary of your registration as a PDF by clicking the button above."
                  : "Thank you for completing the second phase of the registration process! To complete your registration, please proceed with the payment of the registration fees."
                : "Thank you for completing the first stage of the registration process! Please keep a note of your unique application code as it will be essential for future stages of the registration."}
            </p>
            <h2>Application Summary:</h2>
            <h3>Section 1 - Admin:</h3>
            <ul className="confirmList">
              <li>
                <span className="listTitle">Name:</span>{" "}
                {application?.admin_name}
              </li>
              <li>
                <span className="listTitle">Email:</span>{" "}
                {application?.admin_email}
              </li>
              <li>
                <span className="listTitle">Phone Number:</span>{" "}
                {application?.admin_contactNumber}
              </li>
              <li>
                <span className="listTitle">Role:</span>{" "}
                {application?.admin_role}
              </li>
            </ul>
            <h3>Section 2 - Organization:</h3>
            <ul className="confirmList">
              <li>
                <span className="listTitle">Name:</span>{" "}
                {application?.organization_name}
              </li>
              <li>
                <span className="listTitle">Email:</span>{" "}
                {application?.organization_email}
              </li>
              <li>
                <span className="listTitle">Phone Number:</span>{" "}
                {application?.organization_contactNumber}
              </li>
              <li>
                <span className="listTitle">Country/City/Street:</span>{" "}
                {application?.organization_country?.name}/
                {application?.organization_city?.name}/
                {application?.organization_street}
              </li>
            </ul>
            <h3>Section 3 - Teams:</h3>
            {application?.secondApplied ? (
              <>
                {application?.teams?.map((team) => (
                  <>
                    <h4>Team {team?.name}:</h4>
                    <ul className="confirmList">
                      <li>
                        <span className="listTitle">Category:</span>{" "}
                        {team?.category?.name}
                      </li>
                      <li>
                        <span className="listTitle">Age Group:</span>{" "}
                        {team?.ageGroup?.name}
                      </li>
                      <li>
                        <span className="listTitle">Coach Name:</span>{" "}
                        {team?.coach?.name}
                      </li>
                      <li>
                        <span className="listTitle">Coach Email:</span>{" "}
                        {team?.coach?.email}
                      </li>
                      <li>
                        <span className="listTitle">Coach Phone Number:</span>{" "}
                        {team?.coach?.contactNumber}
                      </li>
                      <li>
                        <span className="listTitle">Team Members:</span>
                      </li>
                    </ul>
                    <TableContainer
                      component={Paper}
                      sx={{
                        marginBottom: "30px",
                        marginTop: "10px",
                      }}
                    >
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              align="center"
                              style={{ minWidth: "150px" }}
                            >
                              Member Name
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{ minWidth: "150px" }}
                            >
                              Member National Id
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{ minWidth: "150px" }}
                            >
                              Member Birthday
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{ minWidth: "150px" }}
                            >
                              Member Age
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {team?.students?.map((stu) => (
                            <StyledTableRow key={stu.name}>
                              <StyledTableCell align="center">
                                {stu.name}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {stu?.nationalId}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {stu?.birthday?.split("T")?.at(0)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {stu?.age} years
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ))}
                <h3>Section 4 - Registration Fees:</h3>
                <TableContainer component={Paper} sx={{ marginBottom: "30px" }}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">Team</StyledTableCell>
                        <StyledTableCell align="center">Price</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {application?.pricing?.teams?.map((team) => (
                        <StyledTableRow key={team.team}>
                          <StyledTableCell align="center">
                            {team.team}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {team?.priceInCent / 100}{" "}
                            {application?.pricing?.currency}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      <StyledTableRow key={"teams"}>
                        <StyledTableCell align="center">
                          <strong>Teams Fees</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <strong>
                            {(
                              application?.pricing?.teamsPriceInCents / 100
                            ).toFixed(2)}{" "}
                            {application?.pricing?.currency}
                          </strong>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key={"tax"}>
                        <StyledTableCell align="center">
                          <strong>Tax Fees</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <strong>
                            {(
                              application?.pricing?.taxPriceInCents / 100
                            ).toFixed(2)}{" "}
                            {application?.pricing?.currency}
                          </strong>
                        </StyledTableCell>
                      </StyledTableRow>
                      <TotalStyledTableRow key={"total"}>
                        <StyledTableCell align="center">
                          <strong>Total Fees</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <strong>
                            {(
                              application?.pricing?.totalPriceInCents / 100
                            ).toFixed(2)}{" "}
                            {application?.pricing?.currency}
                          </strong>
                        </StyledTableCell>
                      </TotalStyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <ul className="confirmList">
                {application?.teams?.map((team) => (
                  <li>
                    <span className="listTitle">{team?.name}</span> :{" "}
                    {team?.category?.name}
                  </li>
                ))}
              </ul>
            )}
            {application?.paymentTransaction ? (
              <>
                <h3>Section 5 - Payment:</h3>
                <ul className="confirmList">
                  <li>
                    <span className="listTitle">Transaction ID:</span>{" "}
                    {application?.paymentTransaction?.id}
                  </li>
                  <li>
                    <span className="listTitle">Order ID:</span>{" "}
                    {application?.paymentTransaction?.order_id}
                  </li>
                  <li>
                    <span className="listTitle">Date:</span>{" "}
                    {new Date(
                      application?.paymentTransaction?.paiedAt
                    )?.toLocaleDateString()}
                  </li>
                  <li>
                    <span className="listTitle">Time:</span>{" "}
                    {new Date(
                      application?.paymentTransaction?.paiedAt
                    )?.toLocaleTimeString()}
                  </li>
                  <li>
                    <span className="listTitle">Amount:</span>{" "}
                    {(
                      application?.paymentTransaction?.amountCents / 100
                    ).toFixed(2)}{" "}
                    {application?.currency}
                  </li>
                </ul>
              </>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </Box>
  );
};

export default CheckApplicationTemplate;
