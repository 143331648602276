import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./FirstStage.css";
import * as Yup from "yup";
import { Formik } from "formik";
import FormInput from "../../../Components/atoms/FormInput/FormInput";
import TextFieldInput from "../../../Components/atoms/Inputs/TextField/TextFieldInput";
import { PhoneInput } from "react-international-phone";
import phone from "phone";
import "react-international-phone/style.css";
import TeamCard from "../../../Components/molecules/Cards/TeamCard/TeamCard";
import WhiteModal from "../../../Components/molecules/Modal/Modal";
import { PulseLoader } from "react-spinners";
import { arrayOfAcademies } from "../../../helpers/Arrays";
import { Images } from "../../../helpers/Images";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const PhaseOneSchema = Yup.object().shape({
  admin_name: Yup.string().required("Admin Name required !!"),
  admin_email: Yup.string()
    .email("Invalid email !!")
    .required("Admin Email required !!"),
  admin_role: Yup.string().required("Admin Role required !!"),
  organization_id: Yup.string().required("Organization Name required !!"),
  organization_name: Yup.string().when("organization_id", (organization_id) => {
    if (organization_id == -1) {
      return Yup.string().required("Organization Name required !!");
    }
  }),
  organization_email: Yup.string()
    .email("Invalid email !!")
    .required("Admin Email required !!"),
  organization_country: Yup.string().required(
    "Organization Country required !!"
  ),
  organization_gov: Yup.string().required(
    "Organization Governorate required !!"
  ),
  organization_street: Yup.string().required("Organization Street required !!"),
});

const roles = [
  { name: "Team leader", id: 1 },
  { name: "Supervisor", id: 2 },
  { name: "Academy Owner", id: 3 },
  { name: "School organizer", id: 4 },
  { name: "Parents", id: 5 },
];

const FirstStageTemplate = ({
  newObject,
  setNewObject,
  setGoSave,
  confirmOpen,
  setConfirmOpen,
  feesOpen,
  setFeesOpen,
  setFinalSave,
  addLoading,
  countriesList,
  categoriesList,
  newObjectConfirm,
  setCountry,
  cities,
  FinalLoading,
}) => {
  const navigate = useNavigate();
  const [Phone, setPhone] = useState("");
  const [PhoneError, setPhoneError] = useState(null);
  const [PhoneTouched, setPhoneTouched] = useState(false);
  const [PhoneOrg, setPhoneOrg] = useState("");
  const [PhoneOrgError, setPhoneOrgError] = useState(null);
  const [PhoneOrgTouched, setPhoneOrgTouched] = useState(false);
  const [teams, setTeams] = useState([]);
  const [teamsError, setTeamsError] = useState(null);

  useEffect(() => {
    if (
      !phone(Phone)?.isValid ||
      (Phone?.length < 13 && phone(Phone)?.countryCode == "+20")
    )
      setPhoneError("Valid Phone Number Required!!");
    else setPhoneError("");
  }, [Phone]);

  useEffect(() => {
    if (
      !phone(PhoneOrg)?.isValid ||
      (PhoneOrg?.length < 13 && phone(PhoneOrg)?.countryCode == "+20")
    )
      setPhoneOrgError("Valid Phone Number Required!!");
    else setPhoneOrgError("");
  }, [PhoneOrg]);

  return (
    <>
      {false ? (
        <>
          <Box
            sx={{
              width: "100%",
              padding: {
                xs: "70px 20px",
                sm: "70px 60px",
                lg: "70px 120px",
                xl: "150px 120px",
              },
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${Images.gold})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              minHeight: "500px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "center",
                fontSize: { xs: "35px", sm: "40px" },
                fontWeight: "500",
                color: "secondary.main",
              }}
            >
              Registration 2024
            </Typography>
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "center",
                fontSize: { xs: "60px", sm: "70px" },
                fontWeight: "700",
                color: "secondary.main",
              }}
            >
              COMING SOON
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "400",
                color: "secondary.main",
                marginTop: "0px",
              }}
            >
              Are you ready to be the champion this year ?
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
              maxWidth: "1500px",
              margin: "auto",
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "center",
                fontSize: { xs: "40px", sm: "55px" },
                fontWeight: "500",
                color: "primary.darker",
              }}
            >
              Registration - First Stage
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "400",
                color: "primary.darker",
                marginTop: "20px",
              }}
            >
              Please complete the form below to provide details about your
              submission.
            </Typography>
            {/* <Box
              sx={{
                height: "3px",
                maxWidth: { xs: "100px", md: "250px" },
                width: "100%",
                backgroundColor: "primary.main",
                margin: "10px auto 50px auto",
              }}
            /> */}
            <Formik
              initialValues={{
                admin_name: "",
                admin_email: "",
                admin_role: "",
                organization_id: "",
                organization_name: "",
                organization_email: "",
                organization_country: 42,
                organization_city: "",
                organization_street: "",
                organization_gov: "",
              }}
              validationSchema={PhaseOneSchema}
              onSubmit={(values) => {
                if (
                  !PhoneError &&
                  !PhoneOrgError &&
                  teams?.length &&
                  !teams?.find((item) => item?.team_category == "")
                ) {
                  setNewObject({
                    admin_email: values?.admin_email,
                    admin_name: values?.admin_name,
                    admin_contactNumber: Phone,
                    admin_role: values?.admin_role,
                    organization_email: values?.organization_email,
                    organization_name:
                      values?.organization_id == -1
                        ? values?.organization_name
                        : values?.organization_id,
                    organization_contactNumber: PhoneOrg,
                    organization_country: { id: values?.organization_country },
                    organization_city: { id: values?.organization_gov },
                    organization_street: values?.organization_street,
                    teams: teams?.map((team) => ({
                      number: team?.team_number,
                      category: { id: team?.team_category },
                    })),
                  });
                  setGoSave(true);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form
                  className="FormContainer"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: { xs: "column", md: "row" },
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: "14px",
                        margin: "5px",
                        fontWeight: "600",
                        backgroundColor: "primary.main",
                        margin: "5px",
                        width: "fit-content",
                      }}
                      onClick={() => {
                        setFeesOpen(true);
                      }}
                    >
                      Registration Details
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: "14px",
                        margin: "5px",
                        fontWeight: "600",
                        backgroundColor: "primary.main",
                        margin: "5px",
                        width: "fit-content",
                      }}
                      onClick={() => {
                        navigate("/check");
                      }}
                    >
                      Check your application
                    </Button>
                  </Box>
                  <h2 style={{ color: "#595c61" }}>Section 1 - Admin</h2>
                  <div className="FormContainerBox">
                    <div>
                      <TextFieldInput
                        name="admin_name"
                        type="text"
                        placeholder={"Enter your name"}
                        className="Input"
                        label="Admin Name"
                        change={handleChange}
                        blur={handleBlur}
                        newValue={values.admin_name}
                      />
                      {errors.admin_name &&
                        touched.admin_name &&
                        errors.admin_name && (
                          <p className="error">
                            {errors.admin_name &&
                              touched.admin_name &&
                              errors.admin_name}
                          </p>
                        )}
                    </div>
                    <div>
                      <TextFieldInput
                        name="admin_email"
                        type="email"
                        placeholder={"Enter your email"}
                        className="Input"
                        label="Admin Email"
                        change={handleChange}
                        blur={handleBlur}
                        newValue={values.admin_email}
                      />
                      {errors.admin_email &&
                        touched.admin_email &&
                        errors.admin_email && (
                          <p className="error">
                            {errors.admin_email &&
                              touched.admin_email &&
                              errors.admin_email}
                          </p>
                        )}
                    </div>
                    <div style={{ position: "relative" }}>
                      <label className="labelPhone">Admin Phone Number*</label>
                      <PhoneInput
                        label={"Enter Admin Phone Number"}
                        className="textfield Input phoneInput"
                        value={Phone}
                        defaultCountry="eg"
                        onChange={(e) => {
                          setPhone(e);
                        }}
                      />
                      {PhoneError && PhoneTouched ? (
                        <p className="error">{PhoneError}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <TextFieldInput
                        select
                        label="Admin Role"
                        newValue={values.admin_role}
                        placeholder={"Choose your role"}
                        name={"admin_role"}
                        change={handleChange}
                        blur={handleBlur}
                        fullWidth
                        className={`Input`}
                        variant="outlined"
                        SelectProps={{
                          displayEmpty: true,
                        }}
                      >
                        <MenuItem value="" className="" disabled>
                          Choose Admin Role
                        </MenuItem>
                        {roles?.map((item) => (
                          <MenuItem key={item?.id} value={item?.name}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </TextFieldInput>
                      {errors.admin_role &&
                        touched.admin_role &&
                        errors.admin_role && (
                          <p className="error">
                            {errors.admin_role &&
                              touched.admin_role &&
                              errors.admin_role}
                          </p>
                        )}
                    </div>
                  </div>
                  <h2 style={{ color: "#595c61" }}>Section 2 - Organization</h2>
                  <div className="FormContainerBox">
                    <div>
                      <TextFieldInput
                        select
                        label="Organization Name"
                        newValue={values.organization_id}
                        placeholder={"Choose your organization"}
                        name={"organization_id"}
                        change={handleChange}
                        blur={handleBlur}
                        fullWidth
                        className={`Input`}
                        variant="outlined"
                        SelectProps={{
                          displayEmpty: true,
                        }}
                      >
                        <MenuItem value="" className="" disabled>
                          Choose Organization Name
                        </MenuItem>
                        {arrayOfAcademies?.map((item) => (
                          <MenuItem key={item?.id} value={item?.name}>
                            {item?.name}
                          </MenuItem>
                        ))}
                        <MenuItem value={-1} className="">
                          Other
                        </MenuItem>
                      </TextFieldInput>
                      {errors.organization_id &&
                        touched.organization_id &&
                        errors.organization_id && (
                          <p className="error">
                            {errors.organization_id &&
                              touched.organization_id &&
                              errors.organization_id}
                          </p>
                        )}
                    </div>
                    {values.organization_id == -1 ? (
                      <div>
                        <TextFieldInput
                          name="organization_name"
                          type="text"
                          placeholder={"Enter organization name"}
                          className="Input"
                          label="Organization Name"
                          change={handleChange}
                          blur={handleBlur}
                          newValue={values.organization_name}
                        />
                        {errors.organization_name &&
                          touched.organization_name &&
                          errors.organization_name && (
                            <p className="error">
                              {errors.organization_name &&
                                touched.organization_name &&
                                errors.organization_name}
                            </p>
                          )}
                      </div>
                    ) : (
                      ""
                    )}
                    <div>
                      <TextFieldInput
                        name="organization_email"
                        type="email"
                        placeholder={"Enter organization email"}
                        className="Input"
                        label="Organization Email"
                        change={handleChange}
                        blur={handleBlur}
                        newValue={values.organization_email}
                      />
                      {errors.organization_email &&
                        touched.organization_email &&
                        errors.organization_email && (
                          <p className="error">
                            {errors.organization_email &&
                              touched.organization_email &&
                              errors.organization_email}
                          </p>
                        )}
                    </div>
                    <div>
                      <TextFieldInput
                        select
                        label="Organization Country"
                        newValue={values.organization_country}
                        placeholder={"Choose organization country"}
                        name={"organization_country"}
                        change={(newValue) => {
                          handleChange(newValue);
                          setCountry(newValue);
                          console.log(newValue);
                        }}
                        blur={handleBlur}
                        fullWidth
                        className={`Input`}
                        variant="outlined"
                        SelectProps={{
                          displayEmpty: true,
                        }}
                      >
                        <MenuItem value="" className="" disabled>
                          Choose Organization Country
                        </MenuItem>
                        {countriesList?.map((item) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </TextFieldInput>
                      {errors.organization_country &&
                        touched.organization_country &&
                        errors.organization_country && (
                          <p className="error">
                            {errors.organization_country &&
                              touched.organization_country &&
                              errors.organization_country}
                          </p>
                        )}
                    </div>
                    <div>
                      <TextFieldInput
                        select
                        label="Organization Governorate"
                        newValue={values.organization_gov}
                        placeholder={"Choose organization governorate"}
                        name={"organization_gov"}
                        change={handleChange}
                        blur={handleBlur}
                        fullWidth
                        className={`Input`}
                        variant="outlined"
                        SelectProps={{
                          displayEmpty: true,
                        }}
                      >
                        <MenuItem value="" className="" disabled>
                          Choose Organization Governorate
                        </MenuItem>
                        {cities?.map((item) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </TextFieldInput>
                      {errors.organization_gov &&
                        touched.organization_gov &&
                        errors.organization_gov && (
                          <p className="error">
                            {errors.organization_gov &&
                              touched.organization_gov &&
                              errors.organization_gov}
                          </p>
                        )}
                    </div>
                    <div style={{ position: "relative" }}>
                      <label className="labelPhone">
                        Organization Phone Number*
                      </label>
                      <PhoneInput
                        label={"Enter Organization Phone Number"}
                        className="textfield Input phoneInput"
                        value={PhoneOrg}
                        defaultCountry="eg"
                        onChange={(e) => {
                          setPhoneOrg(e);
                        }}
                      />
                      {PhoneOrgError && PhoneOrgTouched ? (
                        <p className="error">{PhoneOrgError}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <TextFieldInput
                        name="organization_street"
                        type="text"
                        placeholder={"Enter organization street"}
                        className="Input"
                        label="Organization Street"
                        change={handleChange}
                        blur={handleBlur}
                        newValue={values.organization_street}
                      />
                      {errors.organization_street &&
                        touched.organization_street &&
                        errors.organization_street && (
                          <p className="error">
                            {errors.organization_street &&
                              touched.organization_street &&
                              errors.organization_street}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="FlexHeader">
                    <div>
                      <h2 style={{ color: "#595c61" }}>Section 3 - Teams</h2>
                      <p style={{ color: "#595c61" }}>
                        Click Add team to add a new team..
                      </p>
                      {teamsError ? <p className="error">{teamsError}</p> : ""}
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setTeams([
                          ...teams,
                          {
                            team_number: teams.length + 1,
                            team_category: "",
                          },
                        ]);
                        setTeamsError(null);
                      }}
                    >
                      Add Team
                    </Button>
                  </div>
                  {teams?.length ? (
                    <div className="teamsCont">
                      {teams?.map((team) => (
                        <TeamCard
                          key={team?.number}
                          categories={categoriesList}
                          teams={teams}
                          team={team}
                          setTeams={setTeams}
                        />
                      ))}
                    </div>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        color: "#595c61",
                        marginBottom: "30px",
                        marginTop: "5px",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      Add At least one team..
                    </p>
                  )}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ minWidth: "200px" }}
                      onClick={() => {
                        if (teams?.find((item) => item?.team_category == "")) {
                          setTeamsError(
                            "Please choose category for all the teams!!"
                          );
                        } else if (!teams?.length) {
                          setTeamsError("At Least 1 Team required!!");
                        } else {
                          setTeamsError(null);
                          setPhoneTouched(true);
                          setPhoneOrgTouched(true);
                          handleSubmit();
                        }
                      }}
                    >
                      {addLoading ? (
                        <div className="loadingSmallBox">
                          <PulseLoader size={6} color="#fff" />
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>

            <WhiteModal
              open={confirmOpen}
              handleClose={() => {
                setConfirmOpen(false);
              }}
              Style={"modalBox"}
              inSide={
                <Box>
                  <h1 style={{ textAlign: "center", marginBottom: "5px" }}>
                    Application Review Before Submission
                  </h1>
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: "30px",
                      marginTop: "5px",
                    }}
                  >
                    Please review all details carefully before submission
                  </p>
                  <h3>Section 1 - Admin:</h3>
                  <ul className="confirmList">
                    <li>
                      <span className="listTitle">Name:</span>{" "}
                      {newObjectConfirm?.admin_name}
                    </li>
                    <li>
                      <span className="listTitle">Email:</span>{" "}
                      {newObjectConfirm?.admin_email}
                    </li>
                    <li>
                      <span className="listTitle">Phone Number:</span>{" "}
                      {newObjectConfirm?.admin_contactNumber}
                    </li>
                    <li>
                      <span className="listTitle">Role:</span>{" "}
                      {newObjectConfirm?.admin_role}
                    </li>
                  </ul>
                  <h3>Section 2 - Organization:</h3>
                  <ul className="confirmList">
                    <li>
                      <span className="listTitle">Name:</span>{" "}
                      {newObjectConfirm?.organization_name}
                    </li>
                    <li>
                      <span className="listTitle">Email:</span>{" "}
                      {newObjectConfirm?.organization_email}
                    </li>
                    <li>
                      <span className="listTitle">Phone Number:</span>{" "}
                      {newObjectConfirm?.organization_contactNumber}
                    </li>
                    <li>
                      <span className="listTitle">Country/City/Street:</span>{" "}
                      {newObjectConfirm?.organization_country?.name}/
                      {newObjectConfirm?.organization_city?.name}/
                      {newObjectConfirm?.organization_street}
                    </li>
                  </ul>
                  <h3>Section 3 - Teams:</h3>
                  <ul className="confirmList">
                    {newObjectConfirm?.teams?.map((team) => (
                      <li>
                        <span className="listTitle">Team {team?.number}</span> :{" "}
                        {team?.category?.name}
                      </li>
                    ))}
                  </ul>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px 0",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ minWidth: "150px", margin: "0 5px" }}
                      onClick={() => {
                        setFinalSave(true);
                      }}
                    >
                      {FinalLoading ? (
                        <div className="loadingSmallBox">
                          <PulseLoader size={6} color="#fff" />
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ minWidth: "150px", margin: "0 5px" }}
                      onClick={() => {
                        setConfirmOpen(false);
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                </Box>
              }
            />
            <WhiteModal
              open={feesOpen}
              handleClose={() => {
                setFeesOpen(false);
              }}
              Style={"modalBox"}
              inSide={
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <CloseIcon
                      style={{ cursor: "pointer", marginTop: "5px" }}
                      onClick={() => {
                        setFeesOpen(false);
                      }}
                    />
                  </Box>
                  <h2 style={{ textAlign: "center", margin: "10px 5px 5px 0" }}>
                    We Do Challenge
                  </h2>
                  <Box
                    sx={{
                      height: "3px",
                      width: "100%",
                      backgroundColor: "primary.main",
                      margin: "10px auto",
                    }}
                  />
                  <h3>Competition Categories:</h3>
                  <ul>
                    <li>Robo Mission Open</li>
                    <li>Robo Mission Spike</li>
                    <li>Robo Mission WeDo</li>
                    <li>Follow Line Open</li>
                    <li>Follow Line Spike</li>
                    <li>Follow Line WeDo</li>
                    <li>Sumo</li>
                    <li>Researchers</li>
                  </ul>
                  <h3>Team Rules:</h3>
                  <ul>
                    <li>From 2 - 5 members guided by a teacher/coach </li>
                  </ul>
                  {/* <h3>Registration Fees:</h3>
                  <ul>
                    <li>5500 EGP per team</li>
                  </ul> */}
                  <h3>
                    The deadline of registration is{" "}
                    <span style={{ color: "red" }}>01 - 08 - 2024.</span>
                  </h3>
                </>
              }
            />
          </Box>
        </>
      )}
    </>
  );
};

export default FirstStageTemplate;
