import React from "react";
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./TeamCard.css";
import TextFieldInput from "../../../atoms/Inputs/TextField/TextFieldInput";
import CloseIcon from "@mui/icons-material/Close";

const sortTeams = (a, b) => {
  if (a?.team_number > b?.team_number) {
    return 1;
  } else {
    return -1;
  }
};

const TeamCard = ({ categories, teams, team, setTeams }) => {
  const handleChange = (e, newCategory) => {
    setTeams(
      [
        ...teams?.filter((item) => item?.team_number != team?.team_number),
        { team_number: team?.team_number, team_category: e.target.value },
      ].sort(sortTeams)
    );
  };
  return (
    <Box className={"teamCardCont"}>
      <div className="FlexHeader">
        <h3>
          Team {team?.team_number}{" "}
          <span style={{ fontSize: "15px", fontWeight: "300" }}>
            - choose teams's category
          </span>
        </h3>
        <Button
          style={{
            margin: 0,
            minWidth: "auto",
            padding: "5px",
            borderRadius: "50%",
            backgroundColor: "#c62828",
          }}
          variant="contained"
          size="small"
          onClick={() => {
            setTeams([
              ...teams
                ?.slice(0, team?.team_number - 1)
                ?.filter((item) => item?.team_number != team?.team_number),
              ...teams
                ?.slice(team?.team_number, teams?.length)
                ?.map((test) => ({
                  team_number: test?.team_number - 1,
                  team_category: test?.team_category,
                })),
            ]);
          }}
        >
          <CloseIcon style={{ fontSize: "15px" }} />
        </Button>
      </div>
      <TextFieldInput
        select
        newValue={team.team_category}
        placeholder={"Choose Team Category"}
        name={"team_category"}
        change={handleChange}
        fullWidth
        className={`InputCategory`}
        variant="outlined"
        SelectProps={{
          displayEmpty: true,
        }}
      >
        <MenuItem value="" className="" disabled>
          Choose Team Category
        </MenuItem>
        {categories?.map((item) => (
          <MenuItem value={item?.id}>{item?.name}</MenuItem>
        ))}
      </TextFieldInput>
      {/* {errors.organization_country &&
          touched.organization_country &&
          errors.organization_country && (
            <p className="error">
              {errors.organization_country &&
                touched.organization_country &&
                errors.organization_country}
            </p>
          )} */}
    </Box>
  );
};

export default TeamCard;
