import React, { useEffect } from "react";
import ResearchersTemplate from "../../../Templetes/Categories/Details/Researchers/Researchers.template";

const ResearchersPage = () => {
  useEffect(() => {
    document.title = "We Do Challenge - Researchers Challenge";
  }, []);
  return <ResearchersTemplate />;
};

export default ResearchersPage;
