import { Box, Button, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./PaymentResult.css";
import { PulseLoader } from "react-spinners";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DangerousRoundedIcon from "@mui/icons-material/DangerousRounded";

const PaymentResultTemplate = ({ loading, Data, navigate }) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      {loading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "100px 0",
          }}
        >
          <PulseLoader size={12} color="#6a0505" />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "0px",
            }}
          >
            {Data?.success == "true" ? (
              <CheckCircleOutlineIcon className="successIcon" />
            ) : (
              <DangerousRoundedIcon className="nonSuccessIcon" />
            )}
          </div>
          <Typography
            variant={"h1"}
            sx={{
              textAlign: "center",
              fontSize: { xs: "40px", sm: "55px" },
              fontWeight: "500",
              color: "primary.darker",
            }}
          >
            {Data?.success == "true"
              ? "Payment Successful!"
              : "Payment Failed!"}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "400",
              color: "primary.darker",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            {Data?.success == "true" ? (
              <span>
                <strong>Your Transaction ID: {Data?.transactionId}</strong>
                <br />
                <strong>
                  Transaction Amount: {(Data?.amountCents / 100).toFixed(2)}{" "}
                  {Data?.currency}
                </strong>
                <br />
                Your payment has been processed successfully!
                <br /> Welcome to the competition! We're thrilled to have you on
                board and wish you the best as you strive to become this year's
                champions.
                <br />
                <span style={{ fontSize: "17px" }}>
                  Please remember to keep a record of your transaction ID for
                  any future inquiries.
                </span>
              </span>
            ) : (
              <span>
                We're sorry, but it seems there was an issue processing your
                payment. Please try again by returning to your application and
                completing the payment process. If you continue to experience
                difficulties, feel free to contact us for assistance. We
                apologize for any inconvenience this may have caused. Thank you
                for your understanding.
              </span>
            )}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontSize: "14px",
                margin: "5px",
                fontWeight: "600",
                backgroundColor: "primary.main",
                margin: "5px",
                width: "fit-content",
              }}
              onClick={() => {
                navigate(
                  Data?.applicationCode
                    ? `/check/${Data?.applicationCode}`
                    : "/check"
                );
              }}
            >
              Check your application
            </Button>
            {Data?.success == "true" ? (
              ""
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  fontSize: "14px",
                  margin: "5px",
                  fontWeight: "600",
                  backgroundColor: "primary.main",
                  margin: "5px",
                  width: "fit-content",
                }}
                onClick={() => {
                  navigate("/contact");
                }}
              >
                contact us
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default PaymentResultTemplate;
