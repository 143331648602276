import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Images } from "../../../helpers/Images";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./Modal.css";

const WhiteModal = ({ open, handleClose, inSide }) => {
  const navigate = useNavigate();
  return (
    <Modal open={open} onClose={handleClose} className="MainModal">
      <Box className="modalCont">{inSide}</Box>
    </Modal>
  );
};

export default WhiteModal;
