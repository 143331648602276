import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../../helpers/Images";
import { categoriesData } from "../../../../helpers/Arrays";
import { Gallery } from "../../../../helpers/gallery";

const RobomissionTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        {false ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "40px", sm: "70px" },
                fontWeight: "500",
                color: "primary.darker",
                margin: "100px auto 0 auto",
              }}
            >
              COMING SOON
            </Typography>
            <Box
              sx={{
                height: "3px",
                maxWidth: { xs: "100px", md: "300px" },
                width: "100%",
                backgroundColor: "primary.main",
                margin: "5px auto 5px auto",
              }}
            />
            <Typography
              variant={"h2"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "40px", sm: "40px" },
                fontWeight: "500",
                color: "primary.darker",
                margin: "5px auto 100px auto",
              }}
            >
              WeDo Challenge
            </Typography>
          </Box>
        ) : (
          <Grid
            spacing={4}
            alignItems={"start"}
            justifyContent={"space-between"}
          >
            <Grid item xs={12} lg={7}>
              <Typography
                variant={"h1"}
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "40px", sm: "55px" },
                  fontWeight: "500",
                  color: "#595c61",
                }}
              >
                Robo Mission
              </Typography>
              <Box
                sx={{
                  height: "3px",
                  maxWidth: { xs: "100px", md: "150px" },
                  width: "100%",
                  backgroundColor: "primary.main",
                  margin: "auto",
                  marginTop: "5px",
                }}
              />
              <Grid item xs={12} lg={4} sx={{ textAlign: "center" }}>
                <br />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <a
                    download
                    href={
                      "https://drive.google.com/uc?export=download&id=1aPLH--V0KrYrf3aB26IVyeEt2OfHwXvj"
                    }
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: "17px",
                        margin: "5px 0",
                        fontWeight: "600",
                        backgroundColor: "#c11a83",
                      }}
                    >
                      Download Detailed Rules (PDF)
                    </Button>
                  </a>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "17px",
                      margin: "5px 0",
                      fontWeight: "600",
                      backgroundColor: "#c11a83",
                    }}
                    onClick={() => navigate("/firststage")}
                  >
                    Register Now
                  </Button>
                </Box>
              </Grid>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "primary.darker",
                  // maxWidth: "750px",
                  margin: "25px 0 10px 0",
                  fontSize: "17px",
                }}
              >
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "30px" },
                    fontWeight: "500",
                    color: "#595c61",
                    marginTop: "50px",
                    marginBottom: "10px",
                  }}
                >
                  Brief:
                </Typography>
                This category is for each team to build and program a robot
                which can help doctors in hospitals by doing specific tasks
                using WeDo 2.0 Kit or Spike essential Kit or even open kit to
                complete the tasks of the game within 3 minutes !
                <br />
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#595c61",
                    marginTop: "20px",
                  }}
                >
                  Lego WeDo category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "primary.main",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Age:
                </span>{" "}
                6 - 10
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Team size:
                </span>{" "}
                2-5 people guided by a coach <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Hardware:
                </span>{" "}
                The controllers, motors and sensors used must be from LEGO
                education products of WeDo Core Set.
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Maximum size:
                </span>{" "}
                Max. 20 x 20 x 25 cm
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#595c61",
                    marginTop: "40px",
                  }}
                >
                  Lego Spike category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#c11a83",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Age:
                </span>{" "}
                6 - 10
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Team size:
                </span>{" "}
                2-5 people guided by a coach
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Hardware:
                </span>{" "}
                The controllers, motors and sensors used must be from LEGO
                education products of Spike essential Core Set.
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Maximum size:
                </span>{" "}
                Max. 20 x 20 x 25 cm
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#595c61",
                    marginTop: "40px",
                  }}
                >
                  Open category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#c11a83",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Age:
                </span>{" "}
                6 - 10
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Team size:
                </span>{" "}
                2-5 people guided by a coach
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Hardware:
                </span>{" "}
                The controllers, motors and sensors used must be from the LEGO
                education of any type of robot or Arduino.
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Maximum size:
                </span>{" "}
                Max. 20 x 20 x 25 cm
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#595c61",
                    marginTop: "40px",
                  }}
                >
                  Machines instructions:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#c11a83",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=18xKGCPWSg9wxUYprH3mU8TnkFEybbTAA"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#c11a83",
                      padding: "3px 10px",
                    }}
                  >
                    download Blood Analysis Machine instructions
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=11ls7pzBBSPSTxjjF4TF8X2O0L2wVGfxZ"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#c11a83",
                      padding: "3px 10px",
                    }}
                  >
                    download Scan Machine instructions
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1Wb3K6dHEiEvf_ToEse6OdBdARXhSykIT"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#c11a83",
                      padding: "3px 10px",
                    }}
                  >
                    download Blood Sample & Meal instructions
                  </Button>
                </a>
                <br />
              </Typography>
              <br />
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "20px",
                justifyContent: "center",
                marginLeft: "16px",
                marginBottom: "20px",
              }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery18}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery55}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery4}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery3}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery2}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery1}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery15}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "primary.main",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
            maxWidth: "1500px",
            margin: "auto",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              textAlign: "center",
              fontSize: { xs: "40px" },
              fontWeight: "500",
              color: "secondary.main",
            }}
          >
            Our All Categories
          </Typography>
          <Box
            sx={{
              height: "3px",
              maxWidth: { xs: "100px", md: "150px" },
              width: "100%",
              backgroundColor: "secondary.main",
              margin: "10px auto 0 auto",
            }}
          />
          <Grid
            container
            spacing={2}
            sx={{ marginTop: "50px", justifyContent: "center" }}
          >
            {categoriesData
              ?.filter((category) => category.title !== "Robo Mission")
              ?.map((item) => (
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      width: "100%",
                      minHeight: { xs: "200px", sm: "250px" },
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      variant={"h3"}
                      sx={{
                        textAlign: "center",
                        fontSize: "35px",
                        fontWeight: "500",
                        color: "secondary.main",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Box
                      sx={{
                        height: "3px",
                        maxWidth: "90px",
                        width: "100%",
                        backgroundColor: "secondary.main",
                        margin: "5px auto 5px auto",
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "primary.main",
                          width: "fit-content",
                          margin: { xs: "5px 0 20px 0", sm: "10px 0 40px 0" },
                        }}
                        onClick={() => navigate(item.link)}
                      >
                        view
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default RobomissionTemplate;
