import { Button } from "@mui/material";
import FormModal from "../FormModal/FormModal";
import "./DeleteForm.css";
import React, { useState } from "react";

function DeleteForm({ click, title = "", disabled }) {
  const [Open, setOpen] = useState(false);
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        style={{ margin: "10px 0" }}
        disabled={disabled}
      >
        Delete Team
      </Button>
      <FormModal
        Open={Open}
        HandleClose={() => setOpen(false)}
        XButton={false}
        Form={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              margin: "20px auto 0",
              maxWidth: "200px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                click();
                setOpen(false);
              }}
            >
              Delete
            </Button>
          </div>
        }
        Title={title}
        CustomStyle={"smallExtra"}
      />
    </div>
  );
}

export default DeleteForm;
